<template>
  <div class="aa content_body" v-loading="loading">
    <div class="nav_header">
      <el-row>
        <el-col :span="20">
          <el-form
            :inline="true"
            size="small"
            @keyup.enter.native="handleSearch"
          >
            <el-form-item label="搜索">
              <el-input
                @clear="handleSearch"
                v-model="ProductName"
                placeholder="输入顾客姓名/手机号/编号"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="任务类型">
              <el-select placeholder="请选择">
                <el-option label="全部" value="1"></el-option>
                <el-option label="生日" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="任务创建时间">
              <el-date-picker
                v-model="datePicker"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @clear="handleSearch"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                size="small"
                @click="handleSearch"
                v-prevent-click
                >搜索</el-button
              >
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>
    <div class="martp_10">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column label="顾客" width="180">
          <template slot-scope="scope">
            <div class="dis_flex flex_y_center">
              <span>{{ scope.row.customer.name }}</span>
              <span class="member font_8 marlt_5">{{
                scope.row.customer.member
              }}</span>
              <img
                v-if="scope.row.customer.wxBool"
                class="marlt_5"
                :src="require('../../../assets/img/wxlogo.png')"
                alt=""
              />
            </div>
            <div>{{ scope.row.customer.phone }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="taskType" label="任务类型" width="180">
        </el-table-column>
        <el-table-column prop="followUpPeople" label="跟进人" width="180">
        </el-table-column>
        <el-table-column prop="followUpTime" label="跟进时间">
        </el-table-column>
        <el-table-column prop="followUpWay" label="跟进方式" width="180">
        </el-table-column>
        <el-table-column label="跟进内容">
          <template slot-scope="scope">
            <div class="oneText">{{ scope.row.followUpCon }}</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "aa",
  data() {
    return {
      loading: false,
      datePicker: "",
      tableData: [
        {
          customer: {
            name: "张三",
            phone: "13233868071",
            member: "黄金会员",
            wxBool: true,
          },
          taskType: "生日",
          followUpPeople: "啊廖",
          followUpTime: "2021-05-10 10:00:00",
          followUpWay: "电话",
          followUpCon:
            "啦啦啦啦啦啊啦啦啦啦啦啦啦啦啦啦啦了了啦啦啦啦啦啦啦啦啦啦啦啦啦啦啦啦啦啦啦",
        },
      ],
    };
  },
};
</script>


<style  lang="scss">
.aa {
  .oneText {
    display: inline-block;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .member {
    display: inline-block;
    border: 1px solid #409eff;
    line-height: 16px;
    padding: 0px 5px;
    color: #409eff;
  }
}
</style>